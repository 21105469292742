import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Footer } from "../components/Bars/Footer";
import { NavBarTop } from "../components/Bars/NavBarTop";
import { useTranslation } from "react-i18next";
import { EventsTimetable } from "../components/Events/EventsTimetable";
import { EventsInfo } from "../components/Events/EventsInfo";

export const Events = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("menu.events");
  });
  return (
    <Grid>
      <Grid container>
        <NavBarTop />
      </Grid>
      <Grid container>
        <Grid item xs={12} marginBottom={"-5px"}>
          <Grid className='pageImg'>
            <div style={{ position: "relative" }}>
              <img
                src={require("../images/headers/cardinal.jpg")}
                alt=''
                className='pageImg'
              />
              <div className='pageTitle'>
                <Typography variant='h4' className='boxBorder' padding={1}>
                  {t("menu.events")}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} className='boxYellow'>
          <EventsInfo />
        </Grid>
        <Grid item xs={12} className='boxBlue'>
          <EventsTimetable />
        </Grid>
      </Grid>
      <Grid container>
        <Footer />
      </Grid>
    </Grid>
  );
};
