import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const EventsInfo = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent={"center"} padding={1}>
      <Grid item className='boxBorder' padding={1}>
        <Typography variant='h5'>{t("events.info.header.title")}</Typography>
        <Typography>{t("events.info.header.date")}</Typography>
        <Typography>{t("events.info.header.activities")}</Typography>
        <Typography>{t("events.info.header.more")}</Typography>{" "}
      </Grid>
      <Grid container padding={0}>
        <Grid
          alignContent={"center"}
          textAlign={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          justifySelf={"center"}
          md={6}
          paddingTop={2}>
          <Typography variant='h5'>{t("events.info.layout.title")}</Typography>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{t("events.info.layout.time.title")}:</TableCell>
                  <TableCell>{t("events.info.layout.time.info")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("events.info.layout.lang.title")}:</TableCell>
                  <TableCell>{t("events.info.layout.lang.info")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("events.info.layout.capacity.title")}:
                  </TableCell>
                  <TableCell>{t("events.info.layout.capacity.info")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("events.info.layout.age.title")}:</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("events.info.layout.age.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("events.info.layout.inscription.title")}:
                  </TableCell>
                  <TableCell>
                    {t("events.info.layout.inscription.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("events.info.layout.condition.title")}:
                  </TableCell>
                  <TableCell>
                    {t("events.info.layout.condition.info")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};
