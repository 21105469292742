import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Typography, Grid, Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AnimalIcon, BirdIcon, FrogIcon, LeafIcon } from "../../data/svgIcons";
import images from "../../data/images";
import { format } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

interface WorkshopDate {
  date: string;
}

interface Workshop {
  title: string;
  dates: WorkshopDate[];
  description: string;
  icon: JSX.Element;
  imageUrl: string;
}

// Static workshops array
const staticWorkshops: Workshop[] = [
  {
    title: "Save the Frog Day",
    dates: [
      { date: "07-04-2025" },
      { date: "08-04-2025" },
      { date: "10-04-2025" },
      { date: "11-04-2025" },
      { date: "07-05-2025" },
      { date: "08-05-2025" },
    ],
    description:
      "Lots of workshops, information and activities all related to frogs",
    icon: <FrogIcon />,
    imageUrl: images.gould,
  },
  {
    title: "World Animal Day (WWF)",
    dates: [{ date: "10-10-2024" }, { date: "11-10-2024" }],
    description:
      "Lots of workshops, information and activities all related to saving animals",
    icon: <AnimalIcon />,
    imageUrl: images.gould,
  },
];

const groupByDateRanges = (workshops: Workshop[]) => {
  return workshops.flatMap((workshop) => {
    const sortedDates = [...workshop.dates]
      .map(({ date }) => new Date(date.split("-").reverse().join("-")))
      .sort((a, b) => a.getTime() - b.getTime());

    const dateRanges = [];
    let start = sortedDates[0];
    let end = start;

    for (let i = 1; i < sortedDates.length; i++) {
      const current = sortedDates[i];
      if (current.getTime() - end.getTime() === 86400000) {
        end = current;
      } else {
        dateRanges.push({ start, end });
        start = current;
        end = current;
      }
    }
    dateRanges.push({ start, end });

    return dateRanges.map(({ start, end }) => ({
      ...workshop,
      dateRange: {
        start: format(start, "dd-MM-yyyy"),
        end: format(end, "dd-MM-yyyy"),
      },
    }));
  });
};

export const EventsTimetable = () => {
  const { t } = useTranslation();

  const [showPastEvents, setShowPastEvents] = useState(false);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Translate workshops and group by date ranges
  const workshops = staticWorkshops.map((workshop) => ({
    ...workshop,
    title: t(workshop.title),
    description: t(workshop.description),
  }));

  const groupedWorkshops = groupByDateRanges(workshops);

  const renderTimelineElements = () => {
    return groupedWorkshops.map((event) => {
      const { start, end } = event.dateRange;
      const isSingleDay = start === end;
      const eventDateText = isSingleDay ? start : `${start} to ${end}`;

      const eventStartDate = new Date(start.split("-").reverse().join("-"));
      const isPastEvent = eventStartDate < currentDate;

      if (isPastEvent && !showPastEvents) {
        return null;
      }

      return (
        <VerticalTimelineElement
          key={event.title + eventDateText}
          iconStyle={{ background: "#4caf50", color: "#fff" }}
          icon={event.icon}>
          <Grid container alignItems='center'>
            <Grid item md={8}>
              <Typography variant='h4'>{event.title}</Typography>
              <Typography variant='body1'>{eventDateText}</Typography>
              <Typography>{event.description}</Typography>
            </Grid>
            <Grid item md={4}>
              <img src={event.imageUrl} alt={event.title} width='100%' />
            </Grid>
          </Grid>
        </VerticalTimelineElement>
      );
    });
  };

  return (
    <Grid container>
      <Grid container justifyContent='center'>
        <Button
          variant='contained'
          onClick={() => setShowPastEvents(!showPastEvents)}>
          {showPastEvents
            ? t("events.timetable.showLess")
            : t("events.timetable.showMore")}
        </Button>
      </Grid>
      <Grid container>
        <VerticalTimeline layout='1-column-left'>
          {renderTimelineElements()}
          <VerticalTimelineElement
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={<NewReleasesIcon />}
            date={t("events.timetable.more") || ""}
          />
        </VerticalTimeline>
      </Grid>
    </Grid>
  );
};
